var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[(_vm.showView)?_c('div',{staticClass:"check-detail-container"},[_c('h6',{staticClass:"detail-name"},[_vm._v(" "+_vm._s(_vm.showContent.brief)+" ")]),_c('div',{staticClass:"message-row"},[_c('span',[_vm._v(" 时间："+_vm._s(_vm.showContent.updateDate)+" ")]),_c('span',[_vm._v("浏览："+_vm._s(_vm.showContent.hitCount))])]),_c('div',{staticClass:"detail-content",domProps:{"innerHTML":_vm._s(_vm.showContent.content)}}),_c('div',{staticClass:"show-others"},[(_vm.showPre)?_c('div',{on:{"click":_vm.goPre}},[_vm._v("上一篇:"+_vm._s(_vm.showPre.name))]):_vm._e(),(_vm.showNext)?_c('div',{on:{"click":_vm.goNext}},[_vm._v("下一篇:"+_vm._s(_vm.showNext.name))]):_vm._e()])]):[_c('div',{staticClass:"scroll-component"},[_c('div',{staticClass:"sub-company-list"},[_c('div',{staticClass:"sub-company-list-inner",style:({
            transform: ("translateX(" + _vm.translateNum + "px)"),
            transition: _vm.startRecord ? '' : 'all 0.2s linear',
          }),on:{"mousedown":_vm.recordDown,"mouseup":_vm.recordUp,"mousemove":_vm.recordMove,"mouseout":_vm.recordOut}},[_vm._l((_vm.subCompanies),function(company,index){return [_c('div',{key:company.id,class:[_vm.companyIndex == index ? 'current-company' : ''],on:{"click":function () { return _vm.companyClick(company, index); }}},[_vm._v(" "+_vm._s(company.name)+" ")])]})],2)]),_c('div',{class:[
          'turn-left',
          _vm.translateNum === 0 ? 'end-left-icon' : 'left-icon' ],on:{"click":_vm.turnLeft}}),_c('div',{class:[
          'turn-right',
          _vm.translateNum <= _vm.maxTranslate ? 'end-right-icon' : 'right-icon' ],on:{"click":_vm.turnRight}})]),_c('div',{staticClass:"news-list"},[_c('scroll-box',{ref:"scrollBox",attrs:{"showButton":false,"direction":"x","innerStyle":{ display: 'flex', alignItems: 'flex-start' }}},[_vm._l((_vm.showInfos),function(info){return [_c('div',{key:info.id,ref:"infoItem",refInFor:true,staticClass:"info-item",on:{"click":function () { return _vm.checkDetail(info); }}},[_c('img',{staticStyle:{"max-width":"285px","max-height":"300px"},attrs:{"src":_vm.servicePath + info.cover}}),_c('div',{staticClass:"item-cover"},[_vm._v(" "+_vm._s(info.name)+" ")])])]})],2)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }