<template>
  <div class="page-container">
    <div class="check-detail-container" v-if="showView">
      <h6 class="detail-name">
        {{ showContent.brief }}
      </h6>
      <div class="message-row">
        <span> 时间：{{ showContent.updateDate }} </span>
        <span>浏览：{{ showContent.hitCount }}</span>
      </div>
      <div class="detail-content" v-html="showContent.content"></div>
      <div class="show-others">
        <div @click="goPre" v-if="showPre">上一篇:{{ showPre.name }}</div>
        <div @click="goNext" v-if="showNext">下一篇:{{ showNext.name }}</div>
      </div>
    </div>
    <template v-else>
      <div class="scroll-component">
        <div class="sub-company-list">
          <div
            @mousedown="recordDown"
            @mouseup="recordUp"
            @mousemove="recordMove"
            @mouseout="recordOut"
            :style="{
              transform: `translateX(${translateNum}px)`,
              transition: startRecord ? '' : 'all 0.2s linear',
            }"
            class="sub-company-list-inner"
          >
            <template v-for="(company, index) in subCompanies">
              <div
                :class="[companyIndex == index ? 'current-company' : '']"
                @click="() => companyClick(company, index)"
                :key="company.id"
              >
                {{ company.name }}
              </div>
            </template>
          </div>
        </div>
        <div
          @click="turnLeft"
          :class="[
            'turn-left',
            translateNum === 0 ? 'end-left-icon' : 'left-icon',
          ]"
        ></div>
        <div
          @click="turnRight"
          :class="[
            'turn-right',
            translateNum <= maxTranslate ? 'end-right-icon' : 'right-icon',
          ]"
        ></div>
      </div>
      <div class="news-list">
        <scroll-box
          :showButton="false"
          direction="x"
          :innerStyle="{ display: 'flex', alignItems: 'flex-start' }"
          ref="scrollBox"
        >
          <template v-for="info in showInfos">
            <div
              @click="() => checkDetail(info)"
              class="info-item"
              :key="info.id"
              ref="infoItem"
            >
              <img
                :src="servicePath + info.cover"
                style="max-width: 285px; max-height: 300px"
              />
              <div class="item-cover">
                {{ info.name }}
              </div>
            </div>
          </template>
        </scroll-box>
        <!-- <template v-for="info in hnews">
          <div
            @click="() => checkDetail(info)"
            class="info-item"
            :key="info.id"
          >
            <img
              :src="servicePath + info.cover"
              style="max-width: 285px; max-height: 300px"
            />
            <div class="item-cover">
              {{ info.name }}
            </div>
          </div>
        </template> -->
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import scrollBox from "@/components/scrollBox";
export default {
  components: {
    scrollBox,
  },
  data() {
    return {
      subCompanies: [],
      companyIndex: 0,
      translateNum: 0,
      showPaneWidth: 1200 - 140,
      startRecord: false,
      startPosition: 0,
      startTranslateNum: 0,
      news: [],
      hnews: [],
      servicePath: "",
      showInfos: [],
      showCurrentInfo: null,
      showContent: null,
      showView: false,
      showNext: null,
      showPre: null,
    };
  },

  computed: {
    postUrl() {
      let menus = this.$store.state.permission.subMenu;
      let routeName = this.$route.name;
      try {
        return menus.childList.find((item) => item.router == routeName)
          .Interface;
      } catch (e) {
        console.log(e);
      }
      return "";
    },
    maxTranslate() {
      return this.showPaneWidth - this.subCompanies.length * 208;
    },
    // rightEnded() {
    //   let totalWidth = this.subCompanies.length * 208;
    //   return this.showPaneWidth - this.translateNum >= totalWidth;
    // },
    ...mapState({
      honorShowView: (state) => state.app.honorShowView,
    }),
  },
  watch: {
    postUrl: {
      deep: true,
      handler() {
        this.getDatas();
      },
    },
    honorShowView(n) {
      this.showView = n;
    },
  },
  methods: {
    formateTranslateNum() {
      let num = Math.abs(this.translateNum);
      if (num === 0) return;
      let more = num % 208;
      if (more === 0) return;
      let nextX = this.translateNum;
      if (more < 104) {
        nextX = this.translateNum + more;
      } else {
        nextX = this.translateNum - 208 + more;
      }
      if (nextX > 0) {
        this.translateNum = 0;
        return;
      }
      if (nextX < this.maxTranslate) {
        this.translateNum = this.maxTranslate;
        return;
      }
      this.translateNum = nextX;
    },
    getDatas() {
      if (!this.postUrl) return;
      this.$post(this.postUrl).then((res) => {
        this.subCompanies = res;
        this.companyClick(res[0], 0);
      });
    },
    companyClick(company, index) {
      this.companyIndex = index;
      this.showInfos = company.news;
      this.news = company.news.filter((item) => item.type == 1);
      this.hnews = company.news.filter((item) => item.type == 2);
      this.servicePath = company.servicePath;
      if (!this.$refs.scrollBox) return
      this.$refs.scrollBox.x = 0
      this.$refs.scrollBox.maxX = 0
    },
    checkDetail(info) {
      let index = this.showInfos.findIndex((item) => item.id == info.id);
      if (index !== 0) {
        this.showPre = this.showInfos[index - 1];
      } else {
        this.showPre = null;
      }
      if (index !== this.showInfos.length - 1) {
        this.showNext = this.showInfos[index + 1];
      } else {
        this.showNext = null;
      }
      this.showCurrentInfo = info;
      this.$post("newsview", {
        id: info.id,
      }).then((res) => {
        this.showContent = res;
        this.showView = true;
        this.$store.commit("setHonorShowView", true);
      });
    },
    goPre() {
      this.checkDetail(this.showPre);
    },
    goNext() {
      this.checkDetail(this.showNext);
    },
    turnLeft() {
      let next = this.translateNum + 208;
      if (next > 0) {
        this.translateNum = 0;
        return;
      }
      this.translateNum = next;
    },
    turnRight() {
      let next = this.translateNum - 208;

      let totalWidth = this.subCompanies.length * 208;
      let flag = this.showPaneWidth - next >= totalWidth;
      if (flag) {
        this.translateNum = this.showPaneWidth - totalWidth;
        return;
      }
      this.translateNum = next;
    },
    recordDown(e) {
      e.preventDefault();
      this.startPosition = e.screenX;
      this.startRecord = true;
      this.startTranslateNum = this.translateNum;
    },
    recordUp(e) {
      e.preventDefault();
      this.startRecord = false;
      this.$nextTick(this.formateTranslateNum);
    },
    recordOut(e) {
      e.preventDefault();
      this.startRecord = false;
      this.$nextTick(this.formateTranslateNum);
    },
    recordMove(e) {
      if (!this.startRecord) return;
      let x = e.screenX;
      let moveX = x - this.startPosition;
      let nextX = this.startTranslateNum + moveX;
      if (nextX > 0) {
        this.translateNum = 0;
        return;
      }
      if (nextX < this.maxTranslate) {
        this.translateNum = this.maxTranslate;
        return;
      }
      this.translateNum = nextX;
    },
  },
  mounted() {
    this.getDatas();
    let params = this.$route.params;
    if (params && params.info && params.list) {
      this.showInfos = params.list;
      this.checkDetail(params.info);
      return;
    }
  },
};
</script>

<style lang='less' scoped>
@import url("./index.less");
</style>